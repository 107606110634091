import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import CurveTop from "../components/svg/curveTop"
import ClientLogos from "../components/clientLogos"
import ArrowDown from "../components/svg/arrowDown"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Quotes from "../components/svg/quotes"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"
import Linkedin from "../components/svg/linkedin"

const AboutPage = () => (

  	<Layout>

      <Seo 
        title="About" 
        description="Madclarity helps Australian brands remove complexity, add clarity, and achieve better media and advertising outcomes."
      />

      <section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text full">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="400"
                     >We are <span className="blue">Madclarity</span></h1>
                     <p
                        data-sal="slide-up" 
                        data-sal-delay="300"
                        data-sal-duration="400"
                     >We're a highly experienced, independent, media consultancy that helps leading brands across Australia achieve better media &amp; advertising outcomes.</p>
                     <div className="btn-row"
                        data-sal="slide-up" 
                        data-sal-delay="500"
                        data-sal-duration="400"
                     >
                        <button onClick={() => scrollTo('#anchor')} className="btn-scroll">  
                            <ArrowDown />    
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad client-section" id="anchor">
         <div className="container">
            <div className="row">
                <div
                    data-sal="zoom-in" 
                    data-sal-delay="400"
                    data-sal-duration="400"
                >
                    <div className="client-title">Just some of the clients we are currently working with</div>
                </div>
                 <ClientLogos />
            </div>
         </div>
      </section>

      <section className="section-pad-btm">
         <div className="container">
            <div className="row">
                <div className="staff-wrapper">
                    <div className="staff">
                        <StaticImage 
                            src="../images/Eric-Faulkner.jpg"
                            alt="Eric Faulkner, Partner and Chairman at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Eric Faulkner</h4>
                            <div className="job-title">Partner &amp; Chairman</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/eric-faulkner-madclarity/" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Mark-Leone.jpg"
                            alt="Mark Leone, Partner and Managing Director at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Mark Leone</h4>
                            <div className="job-title">Partner &amp; Managing Director</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/mark-leone-madclarity" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Noel-Jones.jpg"
                            alt="Noel Jones, Partner & Head of IT / Finance / Creative / Legal at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Noel Jones</h4>
                            <div className="job-title">Partner &amp; Head of IT / Finance / Creative / Legal</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/noel-jones-madclarity/" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Simon-Bellamy.jpg"
                            alt="Simon Bellamy, Partner and Coach at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Simon Bellamy</h4>
                            <div className="job-title">Partner &amp; Coach</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/simon-bellamy-77a7031" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Boots-Miranda.jpg"
                            alt="Boots Miranda, Head of Client Service at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Boots Miranda</h4>
                            <div className="job-title">Head of Client Service</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/boots-miranda-12005b23" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Gol-Ershadi.jpg"
                            alt="Gol Ershadi, Head of Systems Development at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Gol Ershadi</h4>
                            <div className="job-title">Head of Systems Development</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/golnoosh-ershadi-3b5970b9" target="_blank" rel="noreferrer"  aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Alex-McIntosh.jpg"
                            alt="Alex McIntosh, Head of Outdoor media at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Alex McIntosh</h4>
                            <div className="job-title">Partner &amp; Head of Outdoor</div>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Rachel-Yeo.jpg"
                            alt="Rachel Yeo, Analytics Manager at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Rachel Yeo</h4>
                            <div className="job-title">Analytics Manager</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/rachel-yeo-50187248" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Josh-Wilkinson.jpg"
                            alt="Josh Wilkinson, Client Service Consultant at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Josh Wilkinson</h4>
                            <div className="job-title">Client Service Consultant</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/josh-wilkinson-09a9b3194" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Ali-Ershadi.jpg"
                            alt="Ali Ershadi, Systems Developer at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Ali Ershadi</h4>
                            <div className="job-title">Systems Developer</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/ali-ershadi-96a1bb5" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Jasmine-Maruff.jpg"
                            alt="Jasmine Maruff, Client Service Consultant at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Jasmine Maruff</h4>
                            <div className="job-title">Client Service Consultant</div>
                            <a className="li-icon" href="https://www.linkedin.com/in/jasmine-maruff-02b40a1a3" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                        </div>
                    </div>

                    <div className="staff">
                        <StaticImage 
                            src="../images/Katherine-Warwick.jpg"
                            alt="Katherine Warwick, Chief Analyst at Madclarity"
                            className=""
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                        />
                        <div className="staff-text">
                            <h4>Kath Warwick</h4>
                            <div className="job-title">Chief Analyst</div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <div className="container section-pad-btm">
         <div className="row">
            <div className="col-middle-wrapper">
               <div className="col-middle">
                  <Link to="/what-we-do" className="btn btn-lg btn-middle">What we do</Link>
               </div> 
            </div>
         </div>
      </div>

      <div className="curve-top flip">
         <CurveTop />
      </div>
      <section className="bg testimonials">
         <div className="container">
            <div className="row">
               <div className="testimonial-col-wrapper">
                  <div className="testimonial-col"
                     data-sal="fade" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                  >
                        <Quotes />
                     <div className="test-excerpt">
                        ... solve marketing problems ...
                        <span className="blue"> identify improvements</span> ...
                     </div>
                     <Testimonial
                        quote="Eric, Mark, Boots and the team at Madclarity have been helping APL solve marketing problems, measure and benchmark execution and identify improvements for years now. They are a valued part our team, remaining close enough to know how to help and distant enough to be objective."
                        name="Peter Haydon"
                        title="Executive General Manager – Operations, Australian Pork Ltd."
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="curve-btm flip">
         <CurveTop />
      </div>
      
      <Cta />

  </Layout>
)

export default AboutPage